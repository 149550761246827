import React, { Component  } from 'react'

export default class Ad extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
      <div className='ad'>
        <ins className='adsbygoogle'
          data-ad-client='ca-pub-1243623372534504'
          data-ad-format='auto' />
      </div>
    );
  }
}