import React from "react";
import "../style/song-finder.scss";
import youtube from "../images/article-creators/youtube.jpg";
import claim from "../images/article-creators/c-claim.jpg";
import Ad from "../advert.js";

export default function ArticleCreators(props) {
	return (
		<article className="section section-info">
			<h3>
				How To Use Video Clips On Social Media In Compliance With Copyright
				Rules
			</h3>
			<p>
				You want to grow a{" "}
				<a
					href="https://creatoracademy.youtube.com/page/lesson/know-when-fair-use-applies_fair-use-overview_video#:~:text=Fair%20use%20allows%20you%20to,is%20not%20determined%20by%20YouTube"
					target="_blank"
					rel="noopener noreferrer"
				>
					YouTube
				</a>{" "}
				channel and need some footage for your work, but don’t have the budget
				to pay for the clips’ royalties?
			</p>
			<div className="img-wrapper">
				<img src={youtube} alt="YouTube" className="article-img youtube" />
			</div>
			<div className="credits">
				Photo by{" "}
				<a href="https://unsplash.com/@christianw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
					Christian Wiediger
				</a>{" "}
				on{" "}
				<a href="https://unsplash.com/collections/52742263/copyright-articles/067b9bb78fb8b7d3c2fa9e445d7f7bb9?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
					Unsplash
				</a>
			</div>
			<p>
				<a
					href="https://www.copyright.gov/fair-use/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Fair Use
				</a>{" "}
				is an exception to copyright law that allows you to reuse
				copyright-protected material, under certain circumstances, without
				getting permission from the copyright owner. Fair Use is a US legal
				term. Here in Canada for example, as well as in many commonwealth
				countries, the term is{" "}
				<a
					href="https://fair-dealing.ca/what-is-fair-dealing/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Fair Dealing
				</a>
				.
			</p>
			<p>
				The Canadian Copyright Act allows the use of material from a
				copyright-protected work (literature, musical and audiovisual works,
				etc.) without permission when certain conditions are met. People can use
				fair dealing for research, private study, education, parody, satire,
				criticism, review, and news reporting.
			</p>
			<p>
				There are six main criterias for evaluating whether a piece of work
				falls under the Fair Use / Fair Dealing clauses.
			</p>
			<Ad/>
			<ol>
				<li>
					<strong>The purpose of the use / dealing.</strong>
					<br />
					This means: how are you benefiting from the used footage? What is your
					content’s niche? Educational? Film or music review? Even if your
					videos are monetized, you don’t sell them directly.
				</li>
				<li>
					<strong>The character of the use / dealing.</strong>
					<br />
					Or how widely it is distributed. If you have a huge brand you might
					have some more concerns than if your audience just boils down to
					YouTube. Basically, the bigger the distribution - and profit from that
					distributing - the more likely it falls outside from fair use /
					dealing.
				</li>
				<li>
					<strong>The amount of the use / dealing.</strong>
					<br />
					Or how much of the original work you’re using. The risks are limited
					when using one to three second clips. US jurisprudence has endorsed
					the 10% rule of thumb: if a person uses less than 10% of the total
					work, then it is a fair use. However, this rule has been criticized
					and should not be relied upon in determining whether a use will be
					considered a fair use. So in order to be safe, use way less than that.
				</li>
				<li>
					<strong>Alternatives to the use / dealing.</strong>
					<br />
					Or could you have used non-copyrighted material instead? Could you
					have used other sources that are not copyrighted? If the answer is
					yes, you could argue that the familiarity of some pop culture clips
					work best for the conveyed message. Using clips could add flavour,
					humour and highlight commentary without extra explanations, otherwise,
					it wouldn’t have the same impact.
				</li>
				<li>
					<strong>The nature of the work.</strong>
					<br />
					Or whether or not it was previously published. The original intent of
					this criteria was to protect published or confidential material. To be
					safe, instead of independent material, use material that is commonly
					of public knowledge even if legally it is not part of the public
					domain yet.
				</li>
				<li>
					<strong>The effect of the use / dealing on the original work.</strong>
					<br />
					By using it, do you take money out of the original creator’s pocket?
					Could a court prove that by using it, you are affecting the market of
					the original work? Or on the contrary, can you prove that by using
					clips of music, music videos, films and TV shows, you actually promote
					the works to your audience?
				</li>
			</ol>
			<div className="img-wrapper">
				<img src={claim} alt="Copyright Claim" className="article-img claim" />
			</div>
			<div className="credits">
				Photo by{" "}
				<a href="https://unsplash.com/@markuswinkler?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
					Markus Winkler
				</a>{" "}
				on{" "}
				<a href="https://unsplash.com/collections/52742263/copyright-articles/067b9bb78fb8b7d3c2fa9e445d7f7bb9?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
					Unsplash
				</a>
			</div>

			<p>
				In any case, under fair use and fair dealing, it is necessary to credit
				each clip in your captions and in the description of your videos, as
				well as link to where you found the clip.
			</p>
			<p>
				Even if taking all the necessary precautions, be aware that no one is
				100% immune to YouTube penalizing your videos or channel for using any
				of these clips. This is a risk any creator chooses to take since
				copyright legislation is not an exact science and many creators manage
				to get away with it. For example, if you are a gamer, you may have
				watched streamers play copyrighted games live on{" "}
				<a
					href="https://www.twitch.tv/creatorcamp/en/learn-the-basics/copyrights-and-your-channel/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Twitch
				</a>
				. Just like many YouTubers benefit from fair use / dealing, as creators
				are encouraged to produce content and attract advertisement,{" "}
				<a
					href="https://www.onf.ca/film/rip_a_remix_manifesto/"
					target="_blank"
					rel="noopener noreferrer"
				>
					which is extremely difficult or even impossible with strict copyright
					limitations
				</a>
				.
			</p>
			<p className="smaller-font">
				Disclaimer: We do not encourage copyright infringement and we are not
				experts on these matters. This article is the result of research
				concerning avenues for independent creators to possibly explore. Please
				consult a lawyer for complete legal insight.
			</p>
		</article>
	);
}
