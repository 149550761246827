import React from "react";
import "../style/song-finder.scss";
import paris from "../images/eiffel-tower-article/paris-landscape.png";
import eiffelTowerDay from "../images/eiffel-tower-article/eiffel-tower-day.png";
import eiffelTowerNight from "../images/eiffel-tower-article/eiffel-tower-lights.png";
import gustave from "../images/eiffel-tower-article/gustave-eiffel.jpg";
import maurice from "../images/eiffel-tower-article/maurice-koechlin.png";
import emile from "../images/eiffel-tower-article/emile-nouguier.jpeg";
import louvre from "../images/eiffel-tower-article/louvre.png";
import littleMermaid from "../images/eiffel-tower-article/little-mermaid.png";
import romeStation from "../images/eiffel-tower-article/rome-station.png";
import liege from "../images/eiffel-tower-article/liege.png";
import bruges from "../images/eiffel-tower-article/bruges.png";
import NDG2 from "../images/eiffel-tower-article/notre-dame2.png";
import parisKeychains from "../images/eiffel-tower-article/eiffel-tower-keychains.jpeg";
import arc from "../images/eiffel-tower-article/arc-de-triomphe.png";
import gaston from "../images/eiffel-tower-article/statue-gaston.png";
import NDG from "../images/eiffel-tower-article/notre-dame.png";
import sacreCoeur from "../images/eiffel-tower-article/sacre-coeur.png";
import Ad from "../advert.js";

export default function ArticleEiffelTower(props) {
	return (
		<article className="section section-info">
			<h3>Why You can't Use Footage of the Eiffel Tower at Night</h3>
			<p>
            If you browse stock footage websites, you will notice that you can almost never find photos 
            or videos of the Eiffel Tower at night, and there’s a good reason for that. It is illegal to use. {" "}
            It all has to do with a little quirk in French copyright law. 
            </p>
            <div className="img-wrapper">
                <div className="row">
                    <div className="col">
                        <img src={eiffelTowerDay} alt="Eiffel Tower day" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
                    </div>
                    <div className="col">
						<img src={eiffelTowerNight} alt="Eiffel Tower at night" className="article-img" />
                            <div className="credits">
                                ❌
                            </div>
                    </div>
                </div>
            </div>
            <br/>
            <p>
            As a reminder, copyright law basically gives the original creator of a workpiece exclusive right to its sale 
            and distribution, for as long as they live, plus a certain amount of time - which depends on the legislation 
            of each country. In the European Union, which acts as a country, it’s 70 years. 
            </p>
            <p>
            One of the major exceptions to EU copyright law is the Freedom of Panorama. Technically, I can show you these 
            views even though there are a lot of copyrighted ones in them. 
            </p>
            <div className="img-wrapper">
                <div className="row">
                    <div className="col">
                        <img src={liege} alt="Liège" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
                    </div>
                    <div className="col">
						<img src={bruges} alt="Bruges" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
                    </div>
                    <div className="col">
						<img src={NDG2} alt="Cathédrale Notre-Dame de Paris" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <p>
            For the purpose of copyright, buildings are classified as artistic works. These buildings have the same 
            protections as a song or a movie. You can’t just go and build the exact same replica without paying the original 
            architects. But you can take a video of it because of that Freedom of Panorama. Essentially, anything you see 
            outside in public in much of the European Union can be filmed, reproduced, or sold, with exceptions, most notably 
            in France. The European Union allows its members to not have a Freedom of Panorama clause in their copyright laws 
            so France doesn’t have one. As of 2016, you are allowed to take images and videos of copyrighted buildings for 
            personal use, but any commercial use is a copyright violation.
            </p>
            <Ad/>
            <p>
            This means that you can post pictures of your vacations in the EU on social media, but if you’re an influencer 
            that makes money from this content, then no. In the latter case, showing this image is risky without blurring parts of it. 
            </p>
            <div className="img-wrapper">
				<div className="row img">
					<div className="column img first">
						<img src={paris} alt="Paris Landscape" className="article-img" />
                        <div className="credits">
                            ❌
                        </div>
					</div>
				</div>
			</div>
            <p>
            These blurred buildings were all probably designed by architects that are either alive or died within the last 70 years,
            so they are still copyrighted and the architects could sue me since this website has a commercial purpose. 
            </p>
            <p>
            So back to the Eiffel Tower. Why is it even legal to show it during the day and not at night? First, like many parts of 
            Paris, it’s old. Gustave Eiffel didn’t actually design the Eiffel Tower. He bought the design from Stephen Sauvestre whose 
            employees, Maurice Koechlin and Émile Nouguier actually designed the tower. 
            </p>
            <div className="img-wrapper">
                <div className="row">
                    <div className="col">
                        <img src={gustave} alt="Gustave Eiffel" className="article-img" />
                    </div>
                    <div className="col">
						<img src={maurice} alt="Maurice Koechlin" className="article-img" />
                    </div>
                    <div className="col">
						<img src={emile} alt="Émile Nouguier" className="article-img" />
                    </div>
                </div>
            </div>
                <div className="credits">
                    From left to right: Gustave Eiffel, Maurice Koechlin, Émile Nouguier.
                </div>
            <p>
            Nonetheless, when Eiffel bought the design, he bought the copyright too. Eiffel died in 1923. 70 years after that, in 1993, 
            the copyright lapsed, just like with any other artistic work. So the Eiffel Tower design falls into the public domain. 
            All those Eiffel Tower replicas sold on the streets of Paris are fully legal. However, the lights on the Eiffel Tower 
            weren’t installed until 1985, therefore, since they’re considered an artistic work, they are well within their copyright term. 
            For this reason, any photo taken at night when the lights are visible is an illegal reproduction of a copyrighted work.
            </p>
            <div className="img-wrapper">
				<div className="row img">
					<div className="column img first">
						<img src={parisKeychains} alt="Eiffel Tower keychains" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
					</div>
				</div>
			</div>
            <p>
            The Eiffel Tower is not the only example of this. I can’t show you the pyramid of the Louvre museum, the Little Mermaid statue 
            of Copenhagen, or even the main station in Rome. 
            </p>
            <div className="img-wrapper">
                <div className="row">
                    <div className="col">
                        <img src={louvre} alt="Musée du Louvre" className="article-img" />
                        <div className="credits">
                        ❌
                        </div>
                    </div>
                    <div className="col">
						<img src={littleMermaid} alt="Little Mermaid of Copenhagen" className="article-img" />
                            <div className="credits">
                                ❌
                            </div>
                    </div>
                    <div className="col">
						<img src={romeStation} alt="Rome Station" className="article-img" />
                            <div className="credits">
                                ❌
                            </div>
                    </div>
                </div>
            </div>
            <br/>
            <p>
            When I came to learn about this, I realised I have a bunch of photos on my social media that I am not supposed to use for monetisation. 
            In reality, the Eiffel Tower’s copyright has never been enforced in court. As the capital city of the world's most visited country, 
            Paris attracts over 30 million visitors every year, which makes a lot of footage… 
            </p>
			<div className="img-wrapper">
                <div className="row">
                    <div className="col">
                        <img src={arc} alt="Arc de triomphe" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
                    </div>
                    <div className="col">
						<img src={NDG} alt="Cathédrale Notre-Dame de Paris" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
                    </div>
                    <div className="col">
						<img src={sacreCoeur} alt="Basilique du Sacré-Cœur" className="article-img" />
                        <div className="credits">
                            ✅
                        </div>
                    </div>
                    <div className="col">
						<img src={gaston} alt="Musée de la BD Bruxelles" className="article-img" />
                        <div className="credits">
                            ❌
                        </div>
                    </div>
                </div>
            </div>
            <br/>
                <div className="credits">
                    From left to right: The Arc of Triumph, Notre-Dame Cathedral and the Sacré-Cœur Basilica are old enough to fall under public domain. 
                    This statue of Gaston Lagaffe at the Comics Museum of Brussel, as well as the character itself are still copyrighted.
                </div>
		</article>
	);
}