import React from "react";
import "../../style/song-finder.scss";

export default function Disclaimer() {
	return (
		<div className="section section-disclaimer">
			<h3>Disclaimer</h3>
			<p>
				We do not encourage piracy downloads of music but rather give back
				credits where it’s due.
				<br />
				Tune to Royalty identifies audio tracks, matches them to the database,
				and does not save or store any file.
				<br />
				We are not attorneys. For legal advice please see a lawyer.
			</p>
		</div>
	);
}
