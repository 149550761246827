import React from "react";
import "../style/song-finder.scss";
import escalated from "../images/article-memes/escalated.jpeg";
import memeFaces from "../images/article-memes/memeFaces.png";
import notsure from "../images/article-memes/notsure.png";
import memes from "../images/article-memes/memes.jpeg";
import Ad from "../advert.js";

export default function ArticleMemes(props) {
	return (
		<article className="section">
			<h3>Memes About Memes?</h3>
			<div className="img-wrapper">
				<img src={notsure} alt="Not sure if meme" className="notsure-img" />
			</div>
			<p>
				A picture is worth a thousand words. Add a few in-point words and the
				image can be pretty efficient. Memes press a message, add a dose of
				humour, and they can go unpredictably viral. Who could think that{" "}
				<a
					href="https://twitter.com/RealGrumpyCat/status/361300091396579328"
					target="_blank"
					rel="noopener noreferrer"
				>
					a cat with a deformity in the face
				</a>{" "}
				or{" "}
				<a
					href="https://en.wikipedia.org/wiki/Success_Kid"
					target="_blank"
					rel="noopener noreferrer"
				>
					a kid playing in the sand at the beach
				</a>{" "}
				could blow into phenomenons? As memes populate internet - with
				popularity propelled by social media - comes the usual clash between
				free usage, a principle characterized by technology, whether it be for
				creative, entertainment or educational purposes, and copyright
				principles. The limits between public domain and privacy are once again
				blurred.
			</p>
			<div className="img-wrapper">
				<img src={memes} alt="Memes Everywhere" className="memes-img" />
			</div>
			<h3>You've Been Memed</h3>
			<p>
				So memes are uncontrollable. They pop up from all corners of the
				internet every day, to be shared on Reddit forums and Facebook walls
				alike. But how do "memers" react to becoming the unwitting stars of
				shareable content? Meme’s ancestors relied on{" "}
				<a
					href="https://knowyourmeme.com/memes/forever-alone"
					target="_blank"
					rel="noopener noreferrer"
				>
					illustrations
				</a>{" "}
				to convey a message. Now we use real people's faces.
			</p>
			<div className="img-wrapper">
				<img src={memeFaces} alt="Meme Faces" className="memes-faces-img" />
			</div>
			<p>
				In the past, litigious memes have been the subject of legal battles as
				meme creators and publishers have been sued for using people’s images
				without permission, especially those who were not already public
				figures. In 2003, the parents of the unwilling star of the{" "}
				<a
					href="https://knowyourmeme.com/memes/star-wars-kid"
					target="_blank"
					rel="noopener noreferrer"
				>
					"Star Wars Kid"
				</a>{" "}
				video sued their son's classmates for posting the video online. Though
				the suit was settled, memes go to posterity. Once posted, they never
				disappear. Just ask the{" "}
				<a
					href="https://en.wikipedia.org/wiki/Overly_Attached_Girlfriend"
					target="_blank"
					rel="noopener noreferrer"
				>
					"Overly Attached Girlfriend"
				</a>{" "}
				or the{" "}
				<a
					href="https://knowyourmeme.com/memes/disaster-girl"
					target="_blank"
					rel="noopener noreferrer"
				>
					"Disaster Girl"
				</a>
				. In these two cases, the protagonists (or their parent) initiated
				taking and posting their images.
			</p>
			<p>
				But sometimes, meme-celebrity can happen without the subject’s consent,
				the same way the Star Wars Kid learned to deal with his fame. Which
				sometimes resulted in{" "}
				<a
					href="https://www.hm-attorneys.com/can-you-sue-over-a-meme-turns-out-you-can/"
					target="_blank"
					rel="noopener noreferrer"
				>
					lawsuits against the image creators or the news outlet
				</a>{" "}
				for publishing the image especially if it is accompanied by negative
				comments on social media (because people are often meanies on the
				internet too).
			</p>
			<div className="img-wrapper">
				<img
					src={escalated}
					alt="Escalated quickly meme"
					className="escalated-img"
				/>
			</div>
			<p>
				In terms of using other people's work as a meme, within copyright law
				lays the doctrine of{" "}
				<a
					href="https://tunetoroyalty.com/#/articles/copyright101"
					target="_blank"
					rel="noopener noreferrer"
				>
					fair use (in the US), or fair dealing (in most Commonwealth counties)
				</a>{" "}
				which allows the use of a copyrighted work in the creation of new work
				without permission, as long as the use fits within certain parameters. A
				legal finding of fair use/dealing takes into account the following
				factors:
			</p>
			<Ad/>
			<ul>
				<li>
					The <strong>purpose</strong> of the use,
				</li>
				<li>
					The <strong>amount</strong> of the work to be used,
				</li>
				<li>
					The <strong>effect</strong> of the use on the market for or value of
					the original work, and
				</li>
				<li>
					The <strong>nature</strong> of the copyrighted work.
				</li>
			</ul>
			<p>
				Fair use/dealing codifies into law the idea that you can, in fact, take
				someone's original work without permission, and lays out those factors
				to determine when doing so is acceptable.
			</p>
			<p>
				Therefore we have no official definitive answer for whether a use or
				dealing can be considered fair, as every case must be judged on its own
				merits, but there are some types of use generally allowed under fair
				use/dealing, including criticism and commentary, parody, journalism,
				education, and research (just like in this case right!)
			</p>
			<p className="smaller-font">
				Disclaimer: The following is for informational purposes only, and is not
				to be taken as legal advice.
			</p>
		</article>
	);
}
