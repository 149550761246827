import React from "react";
import "../style/song-finder.scss";
import cassette from "../images/article-copyright101/k7.jpg";
import walkman from "../images/article-copyright101/walkman.jpg";
import venyl from "../images/article-copyright101/venyl.jpg";
import ipod from "../images/article-copyright101/ipod.jpg";
import phonograph from "../images/article-copyright101/phonograph.jpg";
import chopin from "../images/article-copyright101/chopin.jpeg";
import copyright from "../images/article-copyright101/c.png";
import creative from "../images/article-copyright101/cc.png";
import copies from "../images/article-copyright101/copies.png";
import summary from "../images/article-copyright101/summary.png";
import Ad from "../advert.js";

export default function ArticleCopyright(props) {
	return (
		<article className="section section-info">
			<h3>The Mysterious Universe of Copyright</h3>
			<p>
				I am from the cassettes, CDs, and Napster generation. In my life span, I
				have listened to music using various tools and their related
				technologies. Being from an African country, we lagged behind in
				technology there (I’m not that old ok!) So I had my tape player, and the
				essential pen to rewind my cassettes or to fix tangled tapes. Then in
				the West, I used to spend time listening to and searching for albums in
				music stores. We switched to mp3 and DVD players. Now vinyl is in and
				the general public is bringing back phonographs to their home. The same
				way any industry evolves, music adapts, sometimes at a crazed pace.{" "}
			</p>
			<div className="img-wrapper">
				<div className="row img">
					<div className="column img first">
						<img src={cassette} alt="cassette" className="article-img" />
					</div>
					<div className="column img first">
						<img src={walkman} alt="walkman" className="article-img" />
					</div>
					<div className="column img first">
						<img src={venyl} alt="venyl" className="article-img" />
					</div>
					<div className="column img first">
						<img
							src={ipod}
							alt="iPod"
							className="article-img"
							style={{ width: "68%" }}
						/>
					</div>
				</div>
			</div>
			<div className="credits">
				©{" "}
				<a
					href="https://unsplash.com/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div>
			<p>
				But let’s go back further in time. Up until 1877. How did people consume
				music before then? The business model for making a living from music was
				based on virtuosity and instrument mastery. Musicians composed music
				under contracts of preference for members of royalty and aristocracy. So
				the King would take a musician under his wing. The musician will compose
				music and perform it for the King and his crowd. So the business model
				was the public execution of music in front of a VIP audience. That was
				before Thomas Edison’s phonograph invention, which captures sound in the
				air and fixes it on a support. The notion of possessing music was born.
			</p>
			<div className="img-wrapper second">
				<div className="row img second">
					<div className="column img second">
						<img src={chopin} alt="walkman" className="article-img" />
						<div className="credits">
							© Hendrik Siemiradzki, Chopin Playing the Piano in Prince
							Radziwill's Salon
						</div>
					</div>
					<div className="column img second">
						<img
							src={phonograph}
							alt="cassette"
							className="article-img phonograph"
							style={{ height: "25em", width: "28em" }}
						/>
						<div className="credits" style={{ textAlign: "left" }}>
							©{" "}
							<a
								href="https://unsplash.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Unsplash.com
							</a>
						</div>
					</div>
				</div>
			</div>
			<p>
				In a streaming era, music has never been as affordable and easy to
				share. We can now (re)produce music from our own laptop. And with the
				machine of sharing information to the collectivity that is internet,
				this sometimes translates into a battle for free content and products
				that escape economic laws. Internet is also the digital equivalent of
				the old organic word-to-mouth. Music is now dematerialized, in the sense
				that I can share an mp3 without being dispossessed from it. Whereas if I
				share a CD with an unreliable friend, I might not get it back in the
				same condition and therefore not be able to play it anymore.
			</p>
			<Ad/>
			<p>
				So how do musicians earn a living nowadays, especially in a time where
				live events are scarce? Copyrights right? But that’s where it gets
				messy. Copyright is a playground where lawyers tend to mingle. How long
				does a copyright last? Well depends in which country. 50 years in
				Canada. 70 years in the US... if that didn’t change since I wrote these
				lines. Which adds a layer of complexity considering internet's
				globalization. So let’s walk through copyrights concepts a little
				closer. What exactly is a copyright?
			</p>
			<h3 className="section-title">Copyright</h3>
			<i className="smaller-font">/ˈkäpēˌrīt/</i>
			<p>
				The exclusive legal right, given to an originator or an assignee to
				print, publish, perform, film, or record literary, artistic, or musical
				material, and to authorize others to do the same.
				<br />
				<i className="smaller-font">Definition of Oxford Languages</i>
			</p>
			<p>
				<img src={copyright} alt="copyright symbol" className="c-logo" />
				It means All rights reserved, that are inherent to the work. Basically,
				we can’t do anything with work that’s copyright protected, unless we get
				permission directly from the author or composer, the representative
				(editor) or collective societies entrusted by authors and composers to
				manage their rights in order to attribute, remix (adapt), share and
				exploit the intellectual property, and eventually pay necessary
				royalties. All rights reserved include two types of rights:
			</p>
			<ul>
				<li>
					A moral right, or a "paternity right" (refers to the person or entity
					that gave birth to the musical work and controls it)
				</li>
				<li>
					A "patrimonial" right through which it would be possible to copy,
					communicate, publicly execute, perform and transfer the musical work,
					like in the case of web streaming.
				</li>
			</ul>
			<p>
				This enclosement clashes with the open functioning core of internet
				which is a sharing and reproduction ecosystem with different licenses.
				To the point where we listen to unaltered music only rarely. But we will
				see that we have alternatives to the traditional copyrights category.
			</p>
			<p>
				<img src={creative} alt="creative common symbol" className="cc-logo" />
				On the other hand of the “hardcore” copyright, we have the{" "}
				<a
					href="https://copyleft.org/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Copyleft
				</a>
				, which grants the right to freely copy, use, modify, distribute and
				broadcast intellectual property as long as the same rights are preserved
				in derivative works created from that property. Here we enter in
				different shades of grey.
			</p>
			<div className="copies-wrapper">
				<img src={copies} alt="copyright types" className="copies-img" />
			</div>
			<p>
				Within that spectrum,{" "}
				<a
					href="https://creativecommons.org/about/"
					target="_blank"
					rel="noopener noreferrer"
				>
					{" "}
					Creative Commons{" "}
				</a>
				is an international non-profit organisation that provides free licences
				for creators to use when making their work available to the public.
				These licences help the creator to give permission for others to use the
				work under certain conditions, letting you create licenses by choosing
				one or more permissions based on parameters like copying, modifying
				(remixing), sharing, broadcasting, distributing, reusing the work, and
				its attribution (by giving due credit to its author). So it is possible
				to attribute, remix, share and exploit as long as we honour the work’s “
				<strong>by</strong>“ attribution.
			</p>
			<p>
				For example,{" "}
				<a
					href="https://help.soundcloud.com/hc/en-us/articles/115003566748-Creative-Commons"
					target="_blank"
					rel="noopener noreferrer"
				>
					Sound Cloud{" "}
				</a>
				gives the option for you to keep all the rights or to let go of some of
				them before your work falls into the public domain and people can start
				to play with it. You can also choose footage on{" "}
				<a
					href="https://vimeo.com/creativecommons"
					target="_blank"
					rel="noopener noreferrer"
				>
					Vimeo
				</a>{" "}
				that falls into this category.
				<br />
				The Creative Commons option is softer but doesn’t mean an abandonment of
				rights. In a nutshell, here are the subcategories of the Creative
				Commons with possible combinations:
			</p>
			<div className="summary-wrapper">
				<img
					alt="Creative Commons Rights"
					src={summary}
					className="summary-img"
				/>
			</div>
			<p>
				If you’re a creator and need to inform users what they can and can’t do
				with your work, you can fill out this{" "}
				<a
					href="https://creativecommons.org/choose/?lang=en"
					target="_blank"
					rel="noopener noreferrer"
				>
					form{" "}
				</a>{" "}
				on Creative Commons’ website. This way, you can write your own license
				without hiring a lawyer. Once the form is filled out, Creative Commons’
				website generates a personalised and legal license (just in case). The
				tool also generates buttons linked to your licence, to include on your
				website. Just like below.
			</p>
			<a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
				<img
					alt="Creative Commons License"
					style={{ borderWidth: "0", marginRight: "5px" }}
					src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png"
				/>
			</a>
			This article is licensed under a{" "}
			<a
				href="http://creativecommons.org/licenses/by-nc/4.0/"
				target="_blank"
				rel="noopener noreferrer"
			>
				Creative Commons Attribution-NonCommercial 4.0 International License
			</a>
			. <br />
			<br />
			Have fun!
		</article>
	);
}
