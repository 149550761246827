import React, { useState } from "react";
import { Col, Tooltip, Spinner } from "reactstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import SongLyrics from "./song-lyrics";
import "./style/song-finder.scss";

function AudioOutput(props) {
	//console.log({ x: props.uploadLoading })
	//console.log(props.analyse);

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);
	//console.log("output", props.analyse);
	return (
		<>
			<Col>
				<div className="section">
					<ul>
						<h3 className="section-title">Song Information</h3>
					</ul>
					{props.uploadLoading ? (
						<span>
							<ul>
								<Spinner animation="grow" /> On its way!
							</ul>
						</span>
					) : (
						<div className="labelStyle">
							<ul>Title: {props.analyse.title}</ul>
							<ul>
								Artist:{" "}
								{props.analyse.artists &&
									props.analyse.artists[0] &&
									props.analyse.artists[0].name}
							</ul>
							<ul>Label: {props.analyse.label}</ul>
							<ul>Album: {props.analyse.album && props.analyse.album.name}</ul>
							<ul>
								ISRC&nbsp;
								<span id="Tooltip">
									<FaRegQuestionCircle className="isrc" />:{" "}
								</span>
								{props.analyse.external_ids && props.analyse.external_ids.isrc}
							</ul>
							<Tooltip
								placement="right"
								isOpen={tooltipOpen}
								target="Tooltip"
								toggle={toggle}
							>
								The International Standard Recording Code (ISRC) allows the
								identification of audio recordings (tracks).
							</Tooltip>
							<ul>Release Date: {props.analyse.release_date}</ul>
						</div>
					)}
				</div>
			</Col>
			<Col>
				<div className="section">
					<SongLyrics
						analyse={props.analyse}
						lyrics={props.lyrics}
						uploadLoading={props.uploadLoading}
					/>
				</div>
			</Col>
		</>
	);
}

export default AudioOutput;
