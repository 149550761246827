import React from "react";
import "../style/song-finder.scss";
import idea from "../images/not-covered/idea.jpeg";
import coca from "../images/not-covered/coca.jpeg";
import jaccoste from "../images/not-covered/jaccoste.jpeg";
import cc from "../images/not-covered/cc.jpeg";
import Ad from "../advert.js";

export default function ArticleNotCovered(props) {
	return (
		<article className="section">
			<h3>What Is Not Covered By Copyright</h3>
            <p>Some type of works falls outside the scope of copyright law. The following categories of work are not eligible for copyright protection, regardless of when they were created and whether or not they bear a copyright notice. So you can use those without fear of liability.</p>
            <p>Most of them are {" "}
                  <a href="https://tunetoroyalty.com/#/articles/how-to-copyright" target="_blank" rel="noopener noreferrer">
                    non-fixed works
                  </a>, 
                    i.e. works that are not fixed in a tangible form of expression. Copyright protection only applies to “original works of authorship” that are fixed in a tangible form of expression. For example, if you attend an improvisational speech that has not been notated or recorded, you may publish the speech. However, you should cite the speech in order to avoid an accusation of {" "}
                    <a href="https://tunetoroyalty.com/#/articles/plagiarism" target="_blank" rel="noopener noreferrer">
                    plagiarism
                    </a>.</p>
            <h4>Ideas, concepts or principles</h4>
            <p>
                Copyright excludes {" "}
                <a href="https://tunetoroyalty.com/#/articles/how-to-copyright" target="_blank" rel="noopener noreferrer">
                ideas
                </a>
                {" "} concepts, or principles, but covers the form in which they are expressed. Merely coming up with an idea does not make you the copyright owner because you haven’t actually expressed anything. You become the copyright owner only when you put that idea into expression through words (e.i. in a blog post) or other tangible forms (e.i. in a video, a photograph, or a podcast). Ideas are not owned by people who simply speak it, but by people who act upon their ideas. Rightfully so. If ideas were copyrighted, no one would ever create anything!
            </p>
            <div className="img-wrapper">
				<img src={idea} alt="Idea" className="imgs" />
			</div>
            <div className="credits">
				Photo by{" "}
				<a
					href="https://unsplash.com/@freegraphictoday?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					AbsolutVision
				</a>
				{" "}on{" "}
				<a
					href="https://unsplash.com/s/photos/ideas?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div> 
        <h4>Facts</h4>
        <p>Facts are not protected under copyright law. Copyright protection applies to “original” works of authorship. Sports scores, historical dates, calendars, the number of people at an event are non-copyrightable facts.</p>
        <p>However, if an author has conducted an original research with a compilation of coordinated and arranged facts, this work might be protected. In the case for example of a genealogy chart, or a biography, a documentary. In each of those instances, the creator of the work would have a copyright in the selective arrangement of the facts, but not the facts themselves.</p>
        <h4>Words, names, titles, short phrases, or familiar symbols</h4>
        <p>In general, copyright does not protect individual words, short phrases, slogans; familiar symbols, designs, mere variations of typographic ornament, lettering, or coloring; mere listings of ingredients or contents, domain names. </p>
        <p>This does not mean that there is no protection on a name, title, word, phrase or symbol, as it may be covered by other legislation: If the name was a trademark, or if it could be proved that the use of the title misleads or confuses the public, (this is known as “passing off”), then there can be issues.</p>
        <p>Passing off is based on the public perception of what the name implies - i.e. you have a very clear idea of what “Coca-Cola” is.</p>
        <Ad/>
        <h4>Designs applied to “useful articles”</h4>
        <p>Copyright protection is generally not available to articles that have a utilitarian function. Examples of these types of useful articles would include lamps, bathroom sinks, clothing, and computer monitors. The treatment of design and copyright is somewhat ambiguous when it comes to industrial design protection.</p>
		<div className="img-wrapper">
			<img src={coca} alt="Coca-Cola" className="imgs" />
		</div>
        <p>
            Some distinctions are clear. For instance, a painting on the side of a truck is protectable under copyright law even though the truck is a useful article. The painting is clearly separable from the utilitarian aspects of the truck. The overall shape of the truck, on the other hand, would not be copyrightable since the shape is an essential part of the truck's utility. 
        </p>
        <p>Another commonly considered example is that of clothing. The print found on the fabric of a skirt or jacket is copyrightable since it exists separately from the utilitarian nature of the clothing. However, there is no copyright in the cut of the cloth, or the design of the skirt or jacket as a whole, since these articles are utilitarian. This is true even for costumes, no copyright protection is granted to the costume as a whole.</p>
        <div className="img-wrapper">
			<img src={jaccoste} alt="J'accoste" />
		</div>
        <div className="credits">
			The logo here constitutes a copyright infringement but not the t-shirt itself
		</div>
        <p>One of the primary purposes for prohibiting copyright protection in useful articles is to prevent the granting of patent-like protection through the copyright laws, as copyrights are so much easier to obtain than patents, there would be no way of limiting this patent-like monopoly to novel inventions.</p> 
        <h4>Works in the public domain</h4>
        <p>You can use any work in the public domain without obtaining permission of the copyright owner. A work falls into the public domain when the copyright expires or if the author uses the {" "}
            <a
			    href="https://tunetoroyalty.com/#/articles/copyright101"
				target="_blank"
				rel="noopener noreferrer"
			>
				Creative Commons Public Domain Dedication
				</a>.</p> 
        <div className="img-wrapper">
			<img src={cc} alt="Creative Commons" className="imgs" />
		</div>
            <div className="credits">
				Photo by{" "}
				<a
					href="https://unsplash.com/@umby?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Umberto
				</a>
				{" "}on{" "}
				<a
					href="https://unsplash.com/s/photos/copyright?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div> 
        <p>To determine if a work falls into the public domain, some factors need to be taken into consideration: when the work was published, whether the author registered the work. </p>
        <p>For example, in Canada, copyright lasts for the life of the author or the last surviving author, the remainder of the calendar year in which the author dies, plus 50 years following the end of that calendar year (on December 31st) – which is the “life plus 50 rule”. In the United States, all works published before 1924 are in the public domain. Works published after 1923, but before 1978 are protected for 95 years from the date of publication. If the work was created, but not published, before 1978, the copyright lasts for the life of the author plus 70 years. Please check the legislation of your {" "}
            <a href="https://en.wikipedia.org/wiki/List_of_countries%27_copyright_lengths" target="_blank" rel="noopener noreferrer">
                country
            </a>.</p>
        <p>On a side note, if that public domain work has been incorporated into another work, the public domain portions of the work are not protected, but the author's new expressive content of the public domain work may be protected by copyright.</p>
        <p>Some examples:</p>
        <ul>
            <li>
                Sculptures or photographs of the Mona Lisa might be copyright-protected as they are new forms of expression even if the Da Vinci work is of public domain.
            </li>
            <li>
                Texts of a book in the public domain may be used freely, but when incorporated into a new work (let’s say, an analysis of the Bible), the new theological book may be protected by copyright. The same goes with any new form of expression (photographs, illustrations, book cover, etc.)
            </li>
        </ul>
        <p>
            <b>DISCLAIMER:</b> This article is a general guideline. Be aware that copyright laws vary substantially between countries, with much of it driven by jurisprudence rather than legislation.  Please seek a local attorney for any specific question regarding copyright laws.
        </p>
        </article>
	);
}