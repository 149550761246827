import React from "react";

import { useParams } from "react-router-dom";

import HomePage from "../../pages/home";
import ArticleMemes from "../../articles/article-memes";
import ArticleCopyright from "../../articles/article-copyright101";
import ArticleCreators from "../../articles/article-creators";
import ArticleHowToCopyright from "../../articles/article-how-to-copyright";
import ArticleCommonMyths from "../../articles/common-myths";
import ArticlePlagiarism from "../../articles/plagiarism";
import ArticleNotCovered from "../../articles/not-covered";
import ArticleFiveCovers from "../../articles/article-five-covers";
import ArticleEiffelTower from "../../articles/eiffel-tower";
import CopyrightFashion from "../../articles/copyright-fashion";

export default function ArticlesPage() {
	//useParams returns objects (one thing or the other)
	const { articleId } = useParams();
	//function & logic of article switch live inside of it
	const getArticleContent = () => {
		//console.log(articleId);
		//Invent articleId
		switch (articleId) {
			case "copyright-fashion":
				return <CopyrightFashion />;
			case "eiffel-tower":
				return <ArticleEiffelTower />;
			case "five-covers":
				return <ArticleFiveCovers />;
			case "not-covered-by-copyright":
				return <ArticleNotCovered />;
			case "plagiarism":
				return <ArticlePlagiarism />;
			case "common-myths":
				return <ArticleCommonMyths />;
			case "how-to-copyright":
				return <ArticleHowToCopyright />;
			case "memes":
				return <ArticleMemes />;
			case "copyright101":
				return <ArticleCopyright />;
			case "creators":
				return <ArticleCreators />;
			default:
				return <HomePage />;
		}
	};

	return <div>{getArticleContent()}</div>;
}
