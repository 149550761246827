import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import AudioAnalyse from "./audio-analyse";
import AudioOutput from "./audio-output";
//import Menu from "./menu";
import { Container, Row, Col } from "reactstrap";
import "./style/song-finder.scss";
import { Switch, Route, Link, useHistory } from "react-router-dom";
//import { Collapse, Button, CardBody, Card } from "reactstrap";
// 1st finds index.js (default page)
import ArticlesPage from "./pages/articles";
import HomePage from "./pages/home";
import About from "./pages/about";
import Disclaimer from "./pages/disclaimer";
import AdSense from 'react-adsense';

function Form(props) {
	const [uploadLoading, setUploadLoading] = React.useState(false);

	useEffect(() => {
		//console.log("files changed");
		//console.log(props.formik.values.analyse);
	}, [props.formik.values.analyse]);

	/* 	const toggleAbout = () => setIsOpenAbout(!isOpenAbout);
	const [isOpenAbout, setIsOpenAbout] = useState(false); */

	/* 	const [isOpenDisclaimer, setIsOpenDisclaimer] = useState(false);
	const toggleDisclaimer = () => setIsOpenDisclaimer(!isOpenDisclaimer); */

	const [articleSelected /* , setArticleSelected */] = useState("");

	let history = useHistory();

	function handleChange(value) {
		history.push(`/articles/${value}`);
	}

	return (
		<>
			<div className="page-header">
				<h1 id="title">Upload a Song to Get the Copyright Information</h1>
			</div>
			<Container fluid>
				<Row>
					<AudioAnalyse
						setFieldValue={props.formik.setFieldValue}
						uploadLoading={uploadLoading}
						setUploadLoading={setUploadLoading}
					/>

					<AudioOutput
						analyse={props.formik.values.analyse}
						uploadLoading={uploadLoading}
					/>
				</Row>
				{/* <Row>
					<Col>
						<Menu />
					</Col>
				</Row> */}

				{/* Backbone of route (list og all pages), implement routing with React Router library */}
				<Row>
					<Col>
						{/* /articles could be a page but in this case not so = exact */}
						<Col>
							<div className="navigation">
								<select
									onChange={(event) => handleChange(event.target.value)}
									value={articleSelected}
									className="info-button"
								>
									<option>More Info</option>
									<option value="copyright-fashion">Copyright in Fashion</option>
									<option value="eiffel-tower">Why You Can't Use Footage of the Eiffel Tower at Night</option>
									<option value="five-covers">Global Music Hits That Are Actually Covers</option>
									<option value="not-covered-by-copyright">What Is Not Covered By Copyright</option>
									<option value="plagiarism">About Plagiarism</option>
									<option value="common-myths">Common Copyright Myths Debunked</option>
									<option value="how-to-copyright">How To Copyright?</option>
									<option value="memes">Do Memes Infringe Copyright?</option>
									<option value="creators">Copyright & Social Media</option>
									<option value="copyright101">Copyright 101</option>
								</select>
							</div>
							{/* 
                            <Row>
                                <div className="navigation">
									<select
										className="info-button"
										value={articleSelected}
										onChange={(event) => {
											setArticleSelected(event.target.value);
										}}
									>
										<option>More Info</option>
										<option value="memes">Do Memes Infringe Copyright?</option>
										<option value="creators">Copyright & Social Media</option>
										<option value="copyright101">Copyright 101</option>
									</select>
								</div> 

								<Link to={`/articles/${articleSelected}`}>
									<div className="navigation">
										<button
											className="info-button"
											color="primary"
											//onClick={toggleMoreInfo}
										>
											Go to article
										</button>
									</div>
								</Link>
                            </Row>
                        */}
						</Col>
					</Col>
					<Col>
						{/* Every single path starts with / */}
						<Link to="/about">
							<div className="navigation">
								<button
									className="about-button"
									color="primary"
									//onClick={toggleAbout}
								>
									About
								</button>
							</div>
						</Link>
					</Col>
					<Col>
						<Link to="/disclaimer">
							<div className="navigation">
								<button
									className="disclaimer-button"
									color="primary"
									// onClick={toggleDisclaimer}
								>
									Disclaimer
								</button>
							</div>
						</Link>
					</Col>
				</Row>
				{/* Switch to redirect from one page to the other */}
				<Switch>
					<Route path="/" exact rel="canonical">		
						<HomePage  />
					</Route>
					<Route path="/about">
						<About />
					</Route>
					<Route path="/disclaimer">
						<Disclaimer />
					</Route>
					<Route path="/articles/:articleId">
						<ArticlesPage />
					</Route>
				</Switch>
				{/* <Switch>
					<Collapse isOpen={isOpenAbout}>
						<Card className="card">
							<CardBody className="card-body">
								<Route path="/" exact>
									<HomePage />
								</Route>
							</CardBody>
						</Card>
					</Collapse>

					<Collapse isOpen={isOpenDisclaimer}>
						<Card className="card">
							<CardBody className="card-body">
								<Route path="/disclaimer">
									<Disclaimer />
								</Route>
							</CardBody>
						</Card>
					</Collapse>
					<Collapse>
						<Card className="card">
							<CardBody className="card-body">
								<Route path="/articles/:articleId">
									<ArticlesPage />
								</Route>
							</CardBody>
						</Card>
					</Collapse>
				</Switch> */}
			</Container>
		</>
	);
}

function App() {
	//console.log("hello");
	return (
		<div>
			<Formik
				initialValues={{ files: null, analyse: {} }}
				onSubmit={(v) => console.log(v)}
			>
				{(formik) => <Form formik={formik} />}
			</Formik>
		</div>
	);
}

export default App;
