import React from "react";
import "../style/song-finder.scss";
import lanboutin from "../images/copyright-fashion/lanboutin.jpg";
import jimmychoo from "../images/copyright-fashion/jimmychoo.jpeg";
import coach from "../images/copyright-fashion/coach.jpeg";
import chanelgucci from "../images/copyright-fashion/chanelgucci.jpeg";
import birkinbag from "../images/copyright-fashion/birkinbag.jpeg";
import { InstagramEmbed } from 'react-social-media-embed';
import Ad from "../advert.js";

export default function CopyrightFashion(props) {
	return (
		<article className="section section-info">
			<h3>Copyright in Fashion</h3>
			<p>
            Major fashion weeks are coming up with designers finally getting 
            to show off months of hard work through their craftsmanship of various 
            clothing and accessories.
            </p>
            <p>
            What you don't see on podiums is the laborious process of 
            designers conceptualizing new creations, sketching them out, 
            and transform them in the fabrics and materials of the clothing 
            they craft. But are these fashion designers rewarded by copyright 
            protection? The short answer is that it depends. And it's an haute mess.
            </p>
            <p>
            First, it depends on the definition of "fashion designs." 
            A fashion design could connote a number of things. 
            It could mean sketches. It could mean a signature colour, 
            like {" "}
                <a href="https://www.thefashionlaw.com/christian-louboutin-red-soles-high-heels-and-a-global-quest-for-trademark-rights/" target="_blank" rel="noopener noreferrer">
                    Louboutin's famous red soles
                </a>. 
            It could mean the way fabric and other design elements 
            are cut and pieced together. It could mean the graphic designs 
            that appear on clothing. It could mean a design imprinted into the fabric.
            </p>
            <div className="img-wrapper">
				<div className="row">
					<div className="col">
						<img src={lanboutin} alt="Lanboutin Red Soles" className="article-img" />
					</div>
                    <div className="col">
						<img src={jimmychoo} alt="Jimmy Choo" className="article-img" />
                    </div>
				</div>
			</div>
             <div className="credits">
                Just paint the sole red and stick the logo on it right? 
                Not according to the {" "}
                    <a href="https://www.theindustry.fashion/victory-for-christian-louboutin-in-red-sole-copyright-case/" target="_blank" rel="noopener noreferrer">
                        European Union's top Court
                    </a>,
                along with {" "}
                    <a href="https://www.latimes.com/business/la-xpm-2012-sep-06-la-fi-mo-christian-louboutin-ysl-20120905-story.html" target="_blank" rel="noopener noreferrer">
                        the U.S. Appeals Court
                    </a>,
                who trademarked Christian Lanboutin's iconic red bottoms, {" "}
                    <a href="https://www.gtlaw.com/en/insights/2018/6/the-christian-louboutin-decision-eu-court-says-red-soles-are-capable-of-trademark-protection" target="_blank" rel="noopener noreferrer">
                        despite French and Luxembourg courts ruling otherwise
                    </a>.
                {" "}
                    <a href="https://www.mandourlaw.com/christian-louboutin-loses-another-trademark-infringement-lawsuit-over-red-soled-shoes/" target="_blank" rel="noopener noreferrer">
                        Tiffany & Co pursued a similar colour-based case
                    </a>.
            </div>
            <h4>
                In the US
            </h4>
            <p>
                In order to determine whether and how fashion is protected by 
                copyright law, it's important to understand the scope of 
                copyright protection for fashion items. The United States 
                Copyright Act does not protect ideas, concepts, or facts. 
                If the colour, polka dots, or a bell sleeve is the latest trend, 
                copyright law protections do not extend to those design elements alone.
            </p>
            <h4>
                Fashion As a Useful Article
            </h4>
            <p>
                The Copyright Act does not extend protection to useful 
                articles either, as those "articles of clothing and costumes 
                are useful articles that ordinarily contain no artistic 
                authorship separable from their overall utilitarian shape."
            </p>
            <p>
                But the United States Copyright Office points an exception to 
                "fanciful costumes." The Office would register these works 
                "if they contain separable pictorial or sculptural authorship. 
                The separable authorship may be physically separable, meaning that 
                the work of art can be physically removed from the costume, 
                or conceptually separable, meaning that the pictorial or sculptural 
                work is independently recognisable and capable of existence apart from 
                the overall utilitarian shape of the useful article." 
            </p>
            <Ad/>
            <h4>
                So Can I Use Copyright To Protect My Fashion Designs Or Not?
            </h4>
            <p>
            More explicitly, here are some design elements where fashion may be protectable under copyright law:
            </p>
            <p>
                <ul>
                    <li>
                        <b>Graphic Designs: </b>Copyright law protects the 
                        designs on the surface of clothing, a canvas, or a 
                        sheet of paper. 
                    </li>
                    <li>
                        <b>Sketches:</b> Original sketches are protected by 
                        copyright law. That means that no one can copy, 
                        distribute, publicly display, etc. your sketch without 
                        your permission. However, copyright protects original expression, {" "}
                        <a href="https://tunetoroyalty.com/#/articles/how-to-copyright" target="_blank" rel="noopener noreferrer">
                            not ideas
                        </a>. 
                        So, because the protection is in the drawing itself, 
                        not the underlying idea, it does not necessarily prevent others 
                        from creating fashions that resemble your sketch.
                    </li>
                    <li>
                        <b>Logos: </b>If a plain t-shirt is not copyrighted, 
                        a logo on it is, as long as it's a separate art from 
                        the fashion item itself, thus ensuring that essential 
                        elements and designs remain part of the public domain. 
                        This is considering that the logo has sufficient 
                        creativity and originality under copyright law. 
                        The Office has refused to register copyrights in 
                        logos when the logos were deemed to have simply 
                        contained common letters, typography, and geometric 
                        shapes without any elaborate or intricate arrangements 
                        that would amount to sufficient creativity under 
                        copyright law. For example, the Office has refused to 
                        register various logos of famous brands including {" "}
                            <a href="https://www.copyright.gov/rulings-filings/review-board/docs/adidas-3-bars.pdf" target="_blank" rel="noopener noreferrer">
                                Adidas's 3 Bars logo
                            </a> and {" "}
                            <a href="https://www.copyright.gov/rulings-filings/review-board/docs/tommy-hilfiger-flag.pdf" target="_blank" rel="noopener noreferrer">
                                Tommy Hilfiger's Flag logo
                            </a>.
                        However, designers can still generally find protections for their brands' logos under trademark law. 
                    </li>
                    <li>
                        <b>Textile Design: </b>A producer of fabrics can rely 
                        on copyright to protect designs imprinted in or on 
                        fabric, only if the design contains a sufficient amount 
                        of creative expression. For example, the Copyright Office 
                        rejected Coach's registration application for a fabric design 
                        containing a pattern consisting of two linked Cs facing each 
                        other alternating with two unlinked Cs facing in the same 
                        direction horizontally and vertically. A New York federal 
                        district court held in <i>Coach, Inc. v. Peters</i>, reasoned that
                        under copyright law, mere letters of the alphabet and the 
                        arrangements of that letter were not sufficiently creative or 
                        original enough so that the design was protectable under copyright 
                        law. The Office has also declined {" "}
                            <a href="https://ipharbour.com/blog/latest/dunhills-geometric-pattern-refused-us-copyright-registration-for-lacking-originality/" target="_blank" rel="noopener noreferrer">
                                Alfred Dunhill Limited's request
                            </a> {" "}
                        for copyright registration of its geometric fabric pattern as 
                        "the Work does not contain the requisite originality necessary to 
                        sustain a claim to copyright".
                    </li>
                </ul>
            </p>
            <div className="img-wrapper">
				<div className="row">
					<div className="col">
						<img src={coach} alt="Coach Bag Design" className="article-img" />
					</div>
				</div>
			</div>
             <div className="credits">
                Coach logo. Credit: Wild As Light / Shutterstock
             </div>
             <p>
             And then there are some design elements that copyright law would not protect. 
             But that doesn't mean there are no options for protection of the designer's work. 
             Other types of intellectual property laws, including trademark and {" "}
             <a href="https://tunetoroyalty.com/#/articles/how-to-copyright" target="_blank" rel="noopener noreferrer">
                patent law
            </a> may be available in these instances. 
             </p>
             <p>
                <ul>
                    <li>
                        <b>Colour: </b>Copyright protection does not extend to 
                        colours. So if the fashion design you wish to protect 
                        is a signature colour or a unique colour scheme, 
                        copyright is not the avenue for you. But again that 
                        doesn't mean there are no options for protecting your 
                        intellectual property. 
                    </li>
                    <li>
                        <b>Cut: </b>The way that design elements are cut and 
                        pieced together is not protected by copyright. {" "}
                            <a href="https://tunetoroyalty.com/#/articles/how-to-copyright" target="_blank" rel="noopener noreferrer">
                                The U.S. Supreme Court stated
                            </a> {" "}
                        that copyright affords "no right to prohibit any person 
                        from manufacturing clothing of identical shape, cut, 
                        and dimensions." But there can be other avenues to 
                        pursue with a design patent. Unlike copyright, a design 
                        patent can prevent others from creating fashions that 
                        resemble your sketch.
                    </li>
                </ul>
             </p>
             <h4>
                In Canada
             </h4>
             <p>
             Canadian copyright law does not implement a separability test 
             similar to that of the US. Rather, section 64 and 64.1 of the {" "}
                <a href="https://tunetoroyalty.com/#/articles/how-to-copyright" target="_blank" rel="noopener noreferrer">
                    Copyright Act
                </a>{" "}
            build a legislative regime for the copyrightability of design 
            elements on useful articles. Specifically, section 64(2) 
            establishes that copyright subsists in a design applied to a 
            useful article only if the article is reproduced less than fifty 
            times. Though there are certain {" "}
                <a href="https://tunetoroyalty.com/#/articles/how-to-copyright" target="_blank" rel="noopener noreferrer">
                    exceptions
                </a>{" "}
            to this limitation, it has proven to be as difficult for Canadian 
            designers to claim copyright of their fashion designs.
             </p>
             <p>
                Basically, copyright law has yet to clearly protect clothing.
                The law decided that fashion, or simply clothing, was functional 
                and not creative. Since then, the law has claimed that because 
                clothing serves the function of covering our bodies, it is not 
                art, and not protected by copyright law. It doesn't matter how 
                artistic a piece of clothing is, if it functions to dress us, 
                it cannot be subject to copyright law. Thus designers works' 
                are left vulnerable to unauthorized reproductions.
             </p>
             <p>
             This is the standard the Copyright Office and courts must use 
             when evaluating copyrightability in useful articles like clothing 
             and fashion accessories, including jewlery.
             </p>
             <h4>
                Everyone Copies Everyone
             </h4>
             <p>
                North American laws don't prohibit brands from copying each 
                other. Many fashion designers and brands argue for stronger 
                copyright protection of their fashion designs, most without 
                success. In nations that were largely manufacturers rather than 
                designers, there continues to exist a complex dynamic between 
                granting protection and ensuring that essential elements and 
                designs remain part of the public domain. 
             </p>
             <p>
            From global luxurious brands to {" "}
             <a href="https://www.dazeddigital.com/fashion/article/32145/1/zara-accused-of-ripping-off-la-indie-designer" target="_blank" rel="noopener noreferrer">
                indie designers
                </a>,
            counterfeiting is not a trend, but an industry worth billions of 
            dollars with {" "}
            <a href="https://www.youtube.com/watch?v=YhPPP_w3kNo&ab_channel=DWDocumentary" target="_blank" rel="noopener noreferrer">
                global brands
            </a>{" "}
            like Zara, Shein, Old Navy, Forever21, 
            H&M, Urban Outfitters, legally riping-off high and low designers 
            all the time. In fact, those fast-fashion brands' entire business 
            model is based around stealing trends to sell them quickly. But while 
            these are all cases of "affordable" fashion brands doing the copying, 
            this is also a practice in luxury fashion. {" "}
            <a href="https://www.vox.com/2018/4/27/17281022/fashion-brands-knockoffs-copyright-st" target="_blank" rel="noopener noreferrer">
            Take Gucci's 2018 cruise collection, which included a jacket that 
            was anear-exact replica of one Harlem couturier Dapper Dan
            </a>, who himself was famous for using 
            counterfeit Gucci, Fendi, and Louis Vuitton logo prints to make 
            bomber jackets, jumpsuits, and hoodies. He ran his operation for a 
            decade until these brands sued him for copyright infringement 
            in 1992, and his Harlem store went out of business due to litigation fees. 
            This time around, Gucci ended up settling the dispute by collaborating with 
            Dapper Dan after years of mutual imitation, embracing the 
            <i>nouveau riche</i> logos plastering.
             </p>
             <div className="img-wrapper">
				<div className="row">
					<div className="col">
                        <InstagramEmbed url="https://www.instagram.com/p/BUw9JwsF0pE/" width={328} captioned />
					</div>
				</div>
			</div>
             <div className="credits">
                Gucci called this jacket an "homage" to Day's work.
             </div>
             <p>
             <a href="https://www.dazeddigital.com/fashion/article/28792/1/chanel-accused-of-plagiarism-and-apologises" target="_blank" rel="noopener noreferrer">
                Chanel
            </a>{" "}
             also had a plagiarism scandal and it won't be long before another 
             brand raises the question bringing the issue full circle again.
             </p>
             <p>
             Fashion copyright jurisprudence has left unclear standards for the 
             copyrightability of fashion designs. Designers still natigate between 
             inspiration, infrigment, appropriation, exploitation and appreciation,
             while seeking greater protection of their works.
             </p>
             <div className="img-wrapper">
				<div className="row">
					<div className="col">
						<img src={birkinbag} alt="Birkin Bags" className="article-img" />
					</div>
                    <div className="col">
						<img src={chanelgucci} alt="Chanel Gucci Bags" className="article-img" />
                    </div>
				</div>
			</div>
             <div className="credits">
               An entire industry revolves around counterfeiting these brands' bags because of, well social status.
            </div>
		</article>
	);
}