import React from "react";
import "../style/song-finder.scss";
import obama from "../images/obama.png";
import iheartny from "../images/plagiarism/iheartny.jpeg";
import match from "../images/plagiarism/match.jpeg";
import arial from "../images/plagiarism/arial.png";
import Ad from "../advert.js";

export default function ArticlePlagiarism(props) {
	return (
		<article className="section">
			<h3>About Plagiarism</h3>
                <p>The P word.  Here we explain what is plagiarism and address the blurry line between plagiarism and inspiration, to better understand what is acceptable when gaining inspiration from another creator.</p>
            <h4>Plagiarism vs Copyright</h4>
                <p>Plagiarism and copyright infringement are similar concepts, but not the same. Plagiarism is also the act of using another's work and passing it off as your own. But copyright infringement is a legal offence of a copyright holder’s exclusive rights granted by law, whereas plagiarism is an ethical offence of academic norms against the author; and is not illegal.</p>
                <p>While plagiarism could open up to a copyright infringement claim, there is no legal liability associated with the act of plagiarism.</p>
                <p>A few examples to understand better:</p>
                <ul>
                    <li>
                        If an author publishes a poem on his blog in which he substantially copies from Charles Baudelaire’s <i>Fleurs du mal</i> but passes off the words as his own, he has committed plagiarism. However, the author has not committed copyright infringement because Baudelaire’s work is in the {" "}
                        <a
                            href="https://tunetoroyalty.com/#/articles/not-covered-by-copyright"
                            target="_blank"
                            rel="noopener noreferrer"
			            >
                        public domain
				</a>.
                    </li>
                    <li>
                        In contrast, if a website owner publishes a compilation of contemporary short stories on her website without the permission of the original authors, she would be liable for copyright infringement, even if the compilation properly notes the original authors and thus avoids plagiarism.
                    </li>
                    <li>
                        Finally, if a journalist uses content from yesterday’s daily newspaper as his own original article in a magazine, the journalist has committed both plagiarism and copyright infringement.
                    </li>
                </ul>
                <div className="row">
                    <div className="col">
                        <p>
                            Plagiarism is more commonly practised in the artistic community where borrowing aspects of an image is OK. Everything an artist produces is in some way is affected by what he or she has seen.
                        </p>
                    </div>
                    <div className="col">
                         <a href="https://en.wikipedia.org/wiki/Barack_Obama_%22Hope%22_poster" target="_blank" rel="noopener noreferrer">
                            <img src={obama} alt="Obama" className="img-col"/>
                        </a>
                    </div>
                </div>
                <h4>
                    Plagiarism vs Inspiration
                </h4>
                <p>
                    In a realm of color, shapes, fonts, interfaces… the combinations are infinite. Whether in the natural world or online: conversations, brands, visual platforms and the media that we consume every day inspire us to craft, transform, and fuse these elements to fit our desirable needs. Inspiration is essential to the creative process.
                </p>
                <p>
                    Some cases of plagiarism are obvious. Others, more subtle and maybe apparent only to the victim. It is tough to justify plagiarism when anything can be passed off as “inspiration”. However, there IS a line between plagiarism and inspiration. Here are some examples of both:
                </p>
                <ul>
                    <li>
                        “I ♥ NY”
                    </li>
                </ul>
                <p>Despite being one of the most widely distributed and imitated images in the world, designer Milton Glaser earned a flat $0 on his I ♥ NY logo. Glaser voluntarily designed the logo for an agency to promote tourism in New York’s darkest days in 1977 and gifted it to New York's Museum of Modern Art. Now the logo is trademarked by the New York State Department of Economic Development.</p>
            <div className="row">
                    <div className="col">
                        <p>Fast forward 40 years, the iconic heart is worth millions of dollars with endless I ♥ NY knockoffs. This is an example of plagiarism, yet justified due to the creator’s lack of {" "}
                            <a
                            href="https://tunetoroyalty.com/#/articles/how-to-copyright"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            copyright
                        </a>.</p>
                    </div>
                    <div className="col">
                            <img src={iheartny} alt="I ♥ NY" className="img-col"/>
                                <div className="credits-bis">
                                    Photo by{" "}
                                    <a
                                        href="https://unsplash.com/@artemioz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Artem Zhukov
                                    </a>
                                    {" "}on{" "}
                                    <a
                                        href="https://unsplash.com/s/photos/i-love-new-york?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Unsplash.com
                                    </a>
			        </div>
                </div>
            </div>
                <ul>
                    <li>
                        Swipe off
                    </li>
                </ul>
                <p>Tinder co-founder, Whitney Wolfe, left Tinder after launching sexual harassment lawsuits against fellow co-founder, Justin Mateen. How ironic right. </p>
                <div className="row">
                    <div className="col">
                        <p>
                            Anyway, following her departure, Wolfe went on to create Bumble, a self-proclaimed feminist dating app, where women have to make the first move. The user interaction (UI) and interface design (UX) of the app – users swipe left (for no) and right (for yes) on profiles of potential partners – is inspired by Tinder while providing a different type of experience.
                        </p>
                    </div>
                     <div className="col">
                            <img src={match} alt="It's a match" className="img-col"/>
                                <div className="credits-bis">
                                    Photo by{" "}
                                    <a
                                        href="https://unsplash.com/@yogasdesign?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Yogas Design
                                    </a>
                                    {" "}on{" "}
                                    <a
                                        href="https://unsplash.com/s/photos/tinder?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Unsplash.com
                                    </a>
			                    </div>
                    </div>
                </div>
                <ul>
                    <li>
                        Sans Serif controversy
                    </li>
                </ul>
                <p>One of the most used typefaces, Arial and Helvetica are both very similar, making it difficult to distinguish between the two. Many designers and type enthusiasts argue that Arial is a cheap copy of Helvetica, made by Microsoft to avoid the license fee for Helvetica. You can find analysis articles online comparing the two in terms of figures, symbols, design…</p>
                <div className="row">
                    <div className="col">
                    <p>
                        Typefaces are in the public domain in the US. This is why there are myriads of legitimate commercial typefaces that are basically identical to the untrained eye. Arial files for example come pre-installed with your operating system are only licensed to you through your Windows license. You aren't authorized to distribute them. Computer fonts are a completely different story and are subject to software protections. 
                    </p>
                    </div>
                    <div className="col">
                        <a href="https://es.wikipedia.org/wiki/Archivo:Helvetica_versus_Arial.svg" target="_blank" rel="noopener noreferrer">
                            <img src={arial} alt="Arial" className="img-col"/>        
                        </a>
                    </div>
                </div>
                <ul>
                    <li>
                        Waka Waka
                    </li>
                </ul>
                    <p>This one is a straightforward example of claiming someone else’s work as your own.</p>
                <div className="row">
                    <div className="col">
                        <iframe width="640" height="480" src="https://www.youtube.com/embed/IUSMDzLJtfU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <p>At 0:45</p>
                    </div>
                    <div className="col">
                        <iframe width="640" height="480" src="https://www.youtube.com/embed/-IO6fpjDJY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <p>
                    Tendencies to plagiarize are strong. In summary, pay attention to some cues that might indicate that you lean on the plagiarism side:
                </p>
                <ul>
                    <li>
                        Copying to shorten the work process, saving time and money.
                    </li>
                    <li>
                        Not using the original to avoid paying the license fee.
                    </li>
                    <li>
                        Quick notoriety. Using work that was already tried and tested which guarantees a level of acceptance or popularity.
                    </li>
                    <li>
                        Not giving credit where credit is due.
                    </li>
                    <li>
                        Unconscious influence.
                    </li>
                </ul>
                <Ad/>
            <h4>
                “Good artists imitate, great artists steal” - Pablo Picasso
            </h4>
            <p>
                The above expression was quoted by Steve Jobs as well. We also supposedly heard Albert Einstein quote: “The best thing about being creative is that the original material doesn’t come out.” Or Salvador Dali: “A person who says he doesn’t want to imitate anything can’t make anything.” Paul McCartney once said: “We stole a lot of stuff”. Many creators have declared similar things.
            </p>
            <p>
                Now that we’ve discussed what defines plagiarism vs inspiration, and established that all creators steal as there is no such thing as an original idea, let’s explore how can we ethically and legally use inspiration. Just in case, you know. Some key points to consider:
            </p>
            <ul>
                <li>
                    Draw inspiration from multiple sources
                </li>
            </ul>
            <p>
                The best way to be sued for copyright or trademark infringement is to copy a single piece of work, which is lazy and unprofessional. Instead, adapting from multiple sources forces creators to pick the best most fitting aspects of a concept into their own work.
            </p>
            <ul>
                <li>
                    Do your homework
                </li>
            </ul>
            <p>
                In order to effectively take inspiration, you must first figure out why a piece of work is inspiring. What feeling does it evoke in you? Try to decipher the characteristics of the piece and the mechanisms behind its creation, then replicate it.
            </p>
            <ul>
                <li>
                    Improve the concept
                </li>
            </ul>
            <p>
                Did you add something new to it? Creativity is synthesis. It’s the ability to combine existing ideas in a new. Make sure to always incorporate and combine YOUR ideas. Improve upon your inspiration. This is important to showcase your appreciation of the original work. And of course, acknowledge your peers. 
            </p>
            <ul>
                <li>
                    Credit your source
                </li>
            </ul>
            <p>
                You avoid plagiarism by adequate citing your work. If not sure, ALWAYS ask. Your citations can be informal in style or adhere to the more formal citation conventions if appropriate. If the idea is from some person, then you just say it is from them and you keep writing. If you are using a quote, graphic or whatever, same: attribution solves your problem.
            </p>
            <h4>
                Thou Shalt Take, Not Lie
            </h4>
            <p>
                With all that being said, don’t be afraid to borrow, remix, mashup and, yes, take (or steal according to some). But do it well. Another alleged Picasso quote: “Good artists copy; great artists steal”. And he probably stole that one too.
            </p>
            <p>
                In computer science, Microsoft stole from Apple. Prior to that, Apple stole from Xerox. And if it wasn’t for that, we wouldn’t have the tools at our disposal today, with every software being incompatible with another operating system, making it a nightmare for users.
            </p>
            <p>
                Plagiarism may not always be a bad thing, especially when a work is adapted in another country and infused with a different culture. In the end, the biggest difference is how you rebrand pieces of inspiration as your own. Creators often iterate and reiterate their work so many times that the resulting content is uniquely theirs, making original work professional plagiarism.
            </p>
        <p>
            <b>DISCLAIMER: </b>
            This article is for educational and informational purposes only and not for the purpose of providing legal advice.
        </p>
		</article>
	);
}