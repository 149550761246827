import React from "react";
import "../style/song-finder.scss";
import obama from "../images/obama.png";
import castafiore from "../images/article-five-covers/castafiore.jpeg";
import Ad from "../advert.js";

export default function ArticleFiveCovers(props) {
	return (
		<article className="section">
			<h3>Global Music Hits That Are Actually Covers</h3>
                <p>Songs are not just lyrics and melodies, they are also what they have been able to add at certain times in our lives. How the same song, with a specific message, and a specific performer, can be a hit in a moment in time, and go completely unnoticed with another performer, in another timespan?</p>
                <p>Here we are telling the stories of five immense songs engraved in our heads, and their origins. Who covered what, how, and why.</p>
            <ul>
                <li>
                    R-E-S-P-E-C-T
                </li>
            </ul>
            <div class="flex">
            <iframe width="560" height="315" align="middle" src="https://www.youtube.com/embed/ID5OcLHN7Tk" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>Well, actually we're talking about another song here.</p>
            <p>If we say Aretha Franklin, we think R-E-S-P-C-T. This is not a song but an anthem, considering its phenomenal impact. </p>
            <p>This track comes out in 1967 and will become a feminist emblem, having toped the charts for 12 weeks in the United-States, causing a cultural upheaval.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/cYbs_O_iMfU" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>This song was originally written by a man. Not only that, this initial version by Otis Redding was quite macho, commanding his woman to give him respect when he comes home after a long day of work and due to the fact that he’s the breadwinner.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/7BDw-H_hUzw" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>“All I'm askin' is give me a little respect when I come home”. {" "}
                            <a
                            href="https://www.ssyoutube.com/watch?v=7BDw-H_hUzw&ab_channel=SeriousLee"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        Download the song
                </a> and check out the lyrics on our app above.</p>
                <p>Aretha twisted it asking for respect when <i>he</i> comes home as without her, he wouldn't be anywhere. This will resonate with many women all around the world and remains to this day, a timeless hymn. Most people know this song from Aretha Franklin’s version.</p>
           <ul>
                <li>
                   Twist and turn
                </li>
            </ul>
            <Ad/>
            <p>In the same idea of songs that have marked women's emancipation history, in this case more or less of a sexual emancipation, a song that everyone knows, and which has even been added to the National Recording Registry
                of the United States, as a national treasure, forming part of the historical heritage of American music… can you guess?
            </p>
            <p>
            .
            .
            .
            </p>
            <p>If we say: <i>Voulez-vous coucher avec moi ce soir ?</i></p>
            <p>Very few know that the first demo of Lady Marmelade was sung by men, this version goes completely unnoticed.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/SeWMdoetN6Y" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>The producer will end up offering the song to Pattie Labelle's group. Back then, Pattie the Christian, pretended she did not understand what she was singing about, especially the French verses; that she sang this song - about prostitutes - without understanding, and... without having asked? Nonetheless, this resulted in a total iconic hit, covered hundred of times.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/CkXsYjSLbKA" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>      
                <p>The most famous cover, which won a Grammy, with shouting vocals - over the top is the thing on that side of the pond:</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/RQa7SvVCdZk" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="img-wrapper">
                <img src={castafiore} width="560" height="315" alt="Tintin & Castafiore" />   
            </div>  
            <ul>
                <li>
                    I will always love my song
                </li>
            </ul>
            <p>
                Speaking of flying vocals, one of pop music’s supreme vocalists, namely Whitney Houston, has performed certainly one of the most popular songs and the best-selling track performed by a woman in the history of music.
            </p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/3JWTaaS7LdU" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>Would you know, Whitney Houston never got any royalties on this song. Indeed, she did not write it. This is a cover of a song by Dolly Parton. Back in 1974, her then-boyfriend pressed her to write it, but the song went relatively unnoticed.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/x0bEZH6ZqG4" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>But Dolly believes in her song. So she keeps on pushing it and goes at it again in 1982.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/0KjO3YwlhEE" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>Still doesn't break through that much either. The song ranks 52 on the US charts. Dolly flips around and offers the song to Pattie Labelle, whom we talked about. Pattie kind of puts it on the corner of the desk, thinks about it, tries to hum with it, hesitates.</p>
            <p>One day, a producer calls up Dolly, saying that he is filming a movie with Whitney Houston, inquiring about a cover of “I Will Always Love You”. A lucky strike for Dolly, because the song that was supposed to be covered by Whitney Houston in The Bodyguard was this one:</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/cQywZYoGB1g" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>The producer will eventually change his mind because, at that same time, this particular song was being used in a tin can commercial. The producer didn't want his romantic film to be linked to a tin can project. This is how Dolly hits the jackpot as Whitney Houston's version explodes and continues to stream very well today. On the other hand, it will give Whitney huge worldwide notoriety. Dolly who still believes in her song, writes another country version in 1995 and performs it herself.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/jr8tffNHJno" track="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>But when a vocalist takes on a song like a tornado, it is very hard to cover it again afterwards.</p>
            <ul>
                <li>
                    Cover of covers
                </li>
            </ul>
            <p>
                This is also what happened with "It's all coming back to me now" by Celine Dion. By the time, it had become her biggest hit, and it rocketed her globally. It’s also one of the most expensive music videos in music history. If you type Celine Dion on YouTube, this is the title that will first appear in the search results.
            </p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/j8fHNdrZTSI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>
                The song was initially released in 1989. One famous producer in the 80s, Jim Steinman, wrote a bunch of song and called new artists, for instance, the Pandora Box who will first carry this song.
            </p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/EPx1dF9E2cM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>
                When the title was recorded, Jim Steinman hadshowcased it to several people, including the rocker Meat Loaf, who absolutely wanted to record it. But Jim Steinman thought it was better suited for women… Then Meat Loaf told him: See, you made women sing that and it was a flop… In 1996, Jimmy had the last word, when it totally exploded with Celine Dion. But Meat Loaf still released his own version of the song in 2006, which was met with some success in a couple of European countries. On a side note, it was still in collaboration with a female singer…
            </p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/By82Udvc53w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <ul>
                <li>
                    From country to R&B
                </li>
            </ul>
            <p>
                In 1993, John Michael Montgomery, a young country singer, released a song in which he promised infinite love to his woman. He sings "I Swear" on his guitar and made it to position 42 on US charts.
            </p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/PnJav69UbI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>
                One day, producer David Foster listens to the song. He then reaches out to a friend of his who happens to form a new group of young performers, and made them sing the song. However, John holds on to his copyright, not convinced that his song could go beyond what he has already achieved with it. The producers then go: “bro, give us your terms.” Reluctantly, John accepts insisting on being credited. So the producers guarantee that his name will be written in bold all over. So the producers took the song to this group of four fresh-looking young men for a cover in R&B suave style, with a modern music video. Far from the cowboy in the Savannah image, this version will appeal to a hormonal female audience not so interested in milking cows, and who each have a favourite group member among the four. A year after its first country release, “I Swear” becomes a 1994 hit.
            </p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/25rL-ooWICU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <ul>
                <li>
                    An improbable cover
                </li>
            </ul>
            <p>
                In 2006, an British rock group with infused blues tones, The Zutons, releases a song called "Valérie". The song was a success in England.
            </p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/L3xpmfJp0Xc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>One day Amy Whinehouse hears this song on the radio and likes it. She talks about it to the producer Mark Ronson with whom she was working with on her new album. Mark is surprised, Amy was known not to listen to anything that has come past the '60s. But he says why not. She takes it further, experimenting with it. Mark is even more surprised but goes along. That will give us the song “Valérie” which appears on Mark Ranson's album “Version”, where Amy has completely reclaimed the song.</p>
            <div class="flex">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/bixuI_GV5I0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>Thsi goes to say, whether you prefer the covers or the originals, each version has something different to offer.</p>
            <p className="smaller-font">
                This article itself is a "cover" of a YouTuber's {" "}
                   <a
                            href=" https://www.youtube.com/watch?v=c59_pw1EHpc&ab_channel=Musicfeelingstv"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            video
                </a> (in French).
            </p>
		</article>
	);
}