import React from "react";
import "../style/song-finder.scss";
import court from "../images/article-how-to-copyright/court.jpeg";
import mail from "../images/article-how-to-copyright/mail.jpeg";
import Ad from "../advert.js";

export default function ArticleHowToCopyright(props) {
	return (
		<article className="section">
			<h3>How To Copyright Your Work</h3>
			<p>
				A common question that creators and producers have is: Should I copyright my work?
			</p>
            <p>
                First, a copyrightable work exists just by virtue of having it been created. This work of original authorship is protected by copyright from the moment it is fixed in a tangible medium of expression, i.e not just in your head, you can’t copyright an idea. You have to execute it on a recognizable form to be automatically copyrighted. Meaning, if you materially created something, it is protected by copyright laws. What you could do after this is <b>register</b> your work. In certain fields (such as inventions), it may be possible to apply for a patent. 
            </p>
			<h3>Hope for the best, prepare for the worst</h3>
			<p>
				So if you are in a country that offers a national registry, why should you register your work? Although such a process is voluntary, it can be beneficial to defend or assert your copyright in case of a lawsuit. For example, the copyright registration will be your piece of evidence to enforce in a situation against an alleged infringer that pleads "innocent infringement." An "innocent infringer" can argue in court that they were unaware of any copyrights in the infringed work due to the lack of registration on online databases. Registration can also be produced in the court as evidence to support that the registrant is the owner of the work in case someone claims it.
			</p>
			<div className="img-wrapper">
				<img src={court} alt="Court Case" className="imgs" />
			</div> 
			<div className="credits">
				Photo by{" "}
				<a
					href="https://unsplash.com/@tingeyinjurylawfirm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Tingey Injury Law Firm
				</a>
				{" "}on{" "}
				<a
					href="https://unsplash.com/s/photos/court?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div>
            <h3>“Presumption of authorship”</h3>
			<p>
				In the case of a copyright ownership dispute, courts will be able to make a legal presumption that the registrant is in fact the rightful copyright owner unless the opposite party proves otherwise by presenting contradictory evidence. The burden of proof lies on the person who challenges authorship. They have to prove that they wrote the work first.
            </p>
			<p>
				One true example was an American art fraud case in 1960s involving a married couple. The wife was a painter that made a particular style of art. The husband was the salesman for her art and made a fortune from it. He was initially mistaken as the author of the work. This went on for years as in those times it was easier for a man to be a successful artist. Therefore, the husband would sign his name on the work instead of his wife's name.
			</p>
			<p>	
                After some years they got divorced and there was a dispute about who owned the copyright to all the paintings. According to the presumption rule, it must be assumed the copyright owner was the husband unless the wife could prove otherwise.
			</p>
			<Ad/>
			<p>
                It went to court and the judge settled the question of authorship (copyright ownership) by asking each to paint a picture in the same style as all the other pictures. Obviously, the husband could not paint such a picture whereas the wife could. Thus, she proved otherwise and won the copyright to her work. Tim Burton made a film about it called Big Eyes.
			</p>
			<p>
                Basically, by registering, you are providing notice to the world that this copyright does exist even though it has already existed by the fact that you have created something and is legally recognized as yours. 
			</p>
            <h3>Unicorns, Poor Man’s Copyright, and Other Myths</h3>
			<p>
				We mentioned above a copyright myth which is that copyright can protect ideas. Again, copyright applies to recorded work: documents, music, painting, etc. There is little you can do to prevent someone else from creating their work based on a similar idea as long as they are not copying your work to do so. 
			</p>
			<p>
                Another common misconception is the supposed alternative to copyright registration commonly called the <b><i>poor man's copyright</i></b> or <b><i>the poor man's trademark</i></b> which refers to the practice of mailing a copy of your work to oneself, in whatever current format including a draft. Then you keep the sealed envelop. This would act as a type of registration where if you ever need to assert your copyright proving that as of the date on the timestamp, you had created this work and that it existed at that point. <b><i>This is not a valid way to copyright your work.</i></b>
			</p>
			<div className="img-wrapper">
				<img
					src={mail}
					alt="Mail Box"
					className="imgs"
				/>
			</div>
			<div className="credits">
				Photo by{" "}
				<a
					href="https://unsplash.com/@universaleye?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Universal Eye
				</a>
				{" "}on{" "}
				<a
					href="https://unsplash.com/s/photos/postal-mail?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div>
			<p>
				Evidence such as mail or email records may be evaluated by a court of law to determine or verify who the rightful copyright holder is. Whatever record exists showing the earliest recorded fixation of the work may potentially be considered as evidence by the court and be used to determine ownership. However, any version of the poor man’s copyright is not enough.
			</p>
			<p>
                A private letter/email is relatively weak evidence as compared to registration or documented publication of the work. So <b>a poor man's copyright is not a substitute for registration</b> and it does not confer any of the legal benefits you would normally get by registering - i.e. being able to claim statutory damages, attorney's fees and pursue legal action against copyright infringers in courts, as you can’t even sue if your work is not registered.
			</p>
			<p>
                Not to mention that this method is easily faked (for example by opening a glued envelope with steam, which won’t rip it, and swap the content). In addition, while the work had to have been created on or before it was sent out, it doesn’t indicate when it was actually created. 
			</p>
			<p>
                Besides, registering your copyrights online is relatively cheap nowadays. For example in the case of the <a
					href="https://www.ic.gc.ca/eic/site/cipointernet-internetopic.nsf/eng/h_wr02281.html"
					target="_blank"
					rel="noopener noreferrer">Canadian Intellectual Property Office</a>, the fee is $65 CAD per work. With the <a
					href="https://www.copyright.gov/about/fees.html"
					target="_blank"
					rel="noopener noreferrer">US Copyright Office</a>, it ranges between $45 to $125 depending on the type of work.
			</p>
			<p>
                Although copyright is applied with or without a copyright notice, it is now the best possible protection. With so much digital piracy going on, register your first or final draft, submit the copyright while your work is undergoing editing and revisions prior to publishing in order to avoid leakage. The sooner the better. Anytime your work is loaded or sent through the internet, anytime your work is not in your possession, it is in jeopardy. 
            </p>
            <p>Stay safe.</p>
			<p>
				<b>DISCLAIMER:</b> This article is for educational and informational purposes only and not for the purpose of providing legal advice. 
			</p>
		</article>
	);
}