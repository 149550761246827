import React from "react";
import "../../style/song-finder.scss";

export default function About() {
	return (
		<div className="section section-about">
			<h3>About</h3>
			<p>
				Do you have on your playlist a music track that you love and wish to
				have more information about it? Apps like Shazam and SoundHound are
				useful tools in recognizing music titles, but here you can upload your
				track and get complete legal information, including rights owners, in
				addition to the lyrics, all in one place. Whether you are a professional
				musician that wish to inquire about the copyrights, or a melophile
				seeking more information for your own personal records, Tune To Royalty
				is a free tool that scans the beats and returns a complete credentials
				sheet about your favourite songs, in a word where we usually can't look
				up to an album cover anymore. All you need to do is compress your files
				if larger than 5MB before uploading. Enjoy!
			</p>
		</div>
	);
}
