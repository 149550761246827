import React from "react";
import "../style/song-finder.scss";
import pear from "../images/common-myths/pear.jpeg";
import typer from "../images/common-myths/typer.jpeg";
import books from "../images/common-myths/books.jpeg";
import court2 from "../images/common-myths/court2.jpeg";
import Ad from "../advert.js";

export default function ArticleCommonMyths(props) {
	return (
		<article className="section">
			<h3>Common Copyright Myths Debunked</h3>
            <p>People hold many misconceptions and misunderstandings about copyright laws. Below is a look at some of the typical mayhems surrounding copyright rules.</p>
                    <h4>1. I can copyright names or titles</h4>
                <p>
                <b>REALITY: </b>
                Copyright laws do not apply to items such as names and titles that may be duplicated coincidentally, or that may be legitimately used in unrelated instances.
                </p>
                <p>
                From a copyright perspective, there is no reason why two books cannot be titled “10 Things To Do in Puerto Vallarta”. As long as the content of works themselves are not copied or adapted, no infringement has occurred. For more information, please refer to our article {" "}
                <a
			    href="https://tunetoroyalty.com/#/articles/not-covered"
				target="_blank"
				rel="noopener noreferrer"
			>
				What Is Not Covered By Copyright
				</a>.
                </p>
                <p>
                While copyright protection may not apply, be aware that copyright can apply if the artwork of the symbol or design contains sufficient creativity. Trademark law protects certain names, titles, words, short phrases, slogans, symbols, and designs. For example, trademark law protects the word "Apple," the slogan "Got Milk?" and the Nike symbol of the "swoosh." 
                </p>
            <div className="img-wrapper">
				<img src={pear} alt="Pear" />
			</div> 
                <h4>
                    2. I can post, mail, email my work to copyright it
                </h4>
            <p>
                <b>REALITY: </b>
                The method of mailing/emailing the work to yourself is called the “poor man’s copyright” and was fully covered in our article about{" "} 
				<a
					href="https://tunetoroyalty.com/#/articles/how-to-copyright"
					target="_blank"
					rel="noopener noreferrer"
				>
					How to Copyright
				</a>. 
                Basically, mailing the manuscript to yourself does not copyright it. Once you write a draft, it is copyrighted as you have copyright by virtue of authoring the work. But you should make sure to register it with the competent authority of your country which is a simple, inexpensive process, and is the relevant proof in a court of law. 
            </p>
            
                    <h4>3. No copyright notice, no protection</h4>
            <p>
                <b>REALITY: </b>
                A lot of countries have signed the Berne Convention, which deals with the protection of works and the rights of their authors. It provides creators (authors, musicians, poets, painters, etc.) with the means to control how their works are used, by whom, and on what terms. Among the tools available to authors is the optional possibility to register for a copyright certificate as explained previously. 
            </p>
            <p>
                Again, you automatically own a copyright in your original or unique works when you produce them, whether you registered them or not.  Any work put into a physical medium that can be reproduced, whether on paper, on record, on audio or video, etc. is copyrighted. You don't have to register your copyrights to get ownership of the copyright. So copyright is automatically assigned worldwide. Registration provides legal protection in case of infringement. 
            </p>

                    <h4>4. Everything on the Internet is “public domain” and free to use</h4>
            <p>
                <b>REALITY: </b>
                Depends on what is your understanding of {" "}
                <a
                            href="https://tunetoroyalty.com/#/articles/not-covered-by-copyright"
                            target="_blank"
                            rel="noopener noreferrer"
			            >
                        public domain
				</a>. While work published on the internet may be publicly accessible and sometimes freely consumed, it is certainly not in the public domain.
            </p>
            <p>
                The same way if a newspaper publishes an article on their website, providing buttons for users to share on social media does not mean that you may use it any way you’d like. 
            </p>
            <p>
                A work will fall into the public domain once copyright expires, which is many years after the author’s death (counting the author’s lifetime + a certain number of years varying from one country to another). 
            </p>
            <Ad/>
                <h4>
                    5. Music compositions
                </h4>
            <p>
                <b>REALITY: </b>
                Speaking of copyright expiration, think of the musical compositions of Beethoven, Mozart and such. Their musical partitions might fall in the public domain, but not necessarily orchestras’ adaptations or remixes of these work. A sound recording will have a separate copyright to the underlying musical composition. This means that a new recording of an old piece of music will still be protected under copyright, even if copyright has expired for the original music.
            </p>

                <h4>
                    6. I can copyright my ideas
                </h4>
            <p>
                <b>REALITY: </b>
                As we have already mentioned in our article on {" "}
                <a
					href="https://tunetoroyalty.com/#/articles/how-to-copyright"
					target="_blank"
					rel="noopener noreferrer"
				>
					How to Copyright
				</a>, copyright does not protect ideas, nor does it protect styles, data, information, methods or concepts. Instead, what copyright law protects is the expression of ideas that have been recorded in a material form of any medium - whether written down or otherwise recorded. The underlying reason for this is that ideas are part of the public domain and therefore no one can have a monopoly on an idea, as there is no such thing as a new idea, we can’t reinvent the wheel right?
            </p>
            <p>
                The closest thing to protecting an idea would be the protection of a business idea and not the idea itself. If entrepreneurs work with other parties and partners, they can patent, trademark, copyright or design register their ideas, with the use for example, of confidentiality/non-disclosure, non-compete, non-solicitation, or trade secret agreements.
            </p>
            <div className="img-wrapper">
				<img src={typer} alt="Vintage Typewriter" className="typewriter-img" />
			</div> 
			<div className="credits">
				Photo by{" "}
				<a
					href="https://unsplash.com/@florianklauer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Florian Klauer
				</a>
				{" "}on{" "}
				<a
					href="https://unsplash.com/s/photos/typewriter"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div>

                <h4>
                    7. If I change someone else’s work I can claim it as my own
                </h4>
            <p>
                <b>REALITY: </b>
                Any adaptation will be legally regarded as a derived work; so if you simply adapt the work of others, it will still be their work, and they have every right to object if you publish such work when they have not given you permission to do so. They are also entitled to reclaim any money you make from selling their work
            </p>
            <p>
                The only safe option is to create something that is not copied or adapted from the work of others, or seek the permission of the rights’ owner (you should expect to pay a fee and/or royalties for this). There is nothing to stop you from being inspired by the work of others, but when it comes to your own work, start with a blank sheet and do not try to copy what others have done.
            </p>
            <p>
                Ok, we hear you. What about inspiration? We said that there is no such thing as a new idea, and everything we do, say, and think is based on what came before. So how can we avoid {" "}
                <a
					href="https://tunetoroyalty.com/#/articles/plagiarism"
					target="_blank"
					rel="noopener noreferrer"
				>
					plagiarism
				</a>
                {" "} if we’re always building on what someone else has done?
            </p>
            <p>
                When it comes to inspiration vs copyright infringement or plagiarism (more of a concern for scholars and journalists), how does one define and defend the line? Inspiration is around us and that line is often thin. 
            </p>
            <p>Did you take an exact copy of some or all of someone else’s work? Including characters, fictional settings, and lines of dialogue? Or are you using style elements, ideas, or other things that are not protected by copyright law? Think of it this way:</p>
            <p><ul><li>Inspire: Add or merge to create innovation.</li></ul></p>
            <p><ul><li>Plagiarism: A completely imitated version.</li></ul></p>
            <p>It comes back to the idea that… you can’t copy an idea. But let’s be honest. We know when we appropriate other people’s work and it is easily avoided. So do yourself a favour. Acknowledge the source from which you gathered the information or material. Ask consent when necessary. You can’t steal an idea. We always sit on our predecessors’ shoulders, and copyright also encourages the development of new creative work. But by doing “something similar”, objectively think what if the same was done to you. </p>
            <div className="img-wrapper">
				<img src={books} alt="Books" className="book-img" />
			</div> 
			<div className="credits">
				Photo by{" "}
				<a
					href="https://unsplash.com/@kapfii?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Laura Kapfer
				</a>
				{" "}on{" "}
				<a
					href="https://unsplash.com/s/photos/books?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div>
            
            <h4>8. So, if I credit the author, I don’t need to ask for permission</h4>
            <p><b>REALITY: </b>
            Not necessarily. If you republish an entire chapter of a book, crediting the author does not prevent you from legal actions. But quoting or excerpting someone else’s work falls into one of the greyest areas of copyright laws. There is no legal rule stipulating what quantity is OK to use without seeking permission from the owner of the material.
            </p>

            <h4>9. I can legally copy 10% or three seconds without copyright obligation </h4>
            <p>
                <b>REALITY: </b>
                Well, no. Unless it is allowed under {" "}
                	<a
					href="https://tunetoroyalty.com/#/articles/creators"
					target="_blank"
					rel="noopener noreferrer"
				>
					fair use or fair dealing rules
				</a>, which by the way, is not a blanket standard that covers every situation where a defendant claims this exemption as you still need to accept a certain level of risk, any unauthorised use of copyright work can potentially lead to legal action.
            </p>
            <p>
                By using bits and pieces, there is no rule of thumb that can be applied. Each case must be viewed on its own merit. In cases that have come to trial what is clear is that it is the perceived importance of the copied content rather than simply the quantity that counts.
            </p>
            <p>
                Your best bet is to always seek permission before you use the work of others.
            </p>

            <h4>10. I can use, copy or publish other peoples work if I don't make any money out of it</h4>
            <p>
                <b>REALITY: </b>
                Again, no. Except in specific circumstances permitted under fair dealing/fair use rules, any copying or publication without the consent of the copyright owner is an infringement, and you could face legal action.
            </p>
            <div className="img-wrapper">
				<img src={court2} alt="Court" className="imgs" />
			</div> 
			<div className="credits">
				Photo by{" "}
				<a
					href="https://unsplash.com/@tingeyinjurylawfirm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Tingey Injury Law Firm
				</a>
				{" "}on{" "}
				<a
					href="https://unsplash.com/s/photos/court?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
					target="_blank"
					rel="noopener noreferrer"
				>
					Unsplash.com
				</a>
			</div>

            <h4>
                11. It’s hard to prove copyright infringement
            </h4>
            <p>
                <b>REALITY: </b>
                Not the case. Copyright infringement is a civil offence, not a criminal one. Civil law requires a lower burden of proof, actually making it easier to prove infringement.
            </p>
            <p>
                In a criminal case, the defendant is innocent until proven guilty beyond any reasonable doubt. However, in a civil case, the plaintiff must convince the court that their claim is valid and that on balance of probability it is likely that the defendant is guilty.
            </p>
            <p>
                On a side note, infringement becomes a criminal offence (an offence against the state instead of a dispute between individuals) if the infringement was intentionally committed for the purpose of financial gain, by reproducing or distributing copies of another’s copyrighted material over a certain amount of time or by making it public online. So copyright infringers can be sued civilly and in some cases prosecuted criminally for the same infringing act.
            </p>

            <h4>
                12. I can use someone else’s work if the author has not objected in the past or if the work has never been published
            </h4>
            <p>
                <b>
                    REALITY: </b>
                Just, no. If the work has not fallen into the public domain, may be protected by copyright.
            </p>

        <p>
            <b>DISCLAIMER: </b>
            This article is for educational and informational purposes only and not for the purpose of providing legal advice. Please seek an attorney’s advice for any confusion regarding copyright laws.
        </p>
		</article>
	);
}